.unit-pricelist-v1 {
	.pricelist {
		.mainrow {

			td {
				font-weight: 500;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}

		.subrow {

			td {
				vertical-align: bottom;

				@media (max-width:420px) {
					font-size: 0.9rem;
				}
			}
		}
	}

	.price-hint {
		padding: 10px 0px;
		background: #93c9ff;
		color: #fff;
		padding-left: 10px;
	}
}