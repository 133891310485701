.unit-book-now-v1 {
	@media(min-width:992px) {
		background-color: var(--widget-header-bg-color);
		padding: 20px 15px;
		position: -webkit-sticky;
		position: sticky;
		z-index: 12;
		top: 180px;
	}


}